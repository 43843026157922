<template>
  <div class="banner">
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="image in src" :key="image.id" @click="toPlays(image.data_id)">
        <van-image :src="image.banner_pic" width="100%" lazy-load>
<!--          <template v-slot:error>-->
<!--            <van-image :src="banner" contain width="100%"/>-->
<!--          </template>-->
<!--          <template v-slot:loading>-->
<!--            <van-image :src="banner" contain width="100%"/>-->
<!--          </template>-->
        </van-image>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
import Vue from 'vue'
import { Lazyload } from 'vant'
import { lnicioBanner } from '@/api/lnicio/banner'
import banners from '@/assets/banner.png'
Vue.use(Lazyload)
export default {
  name: 'banner',
  components: {},
  props: ['channelId'],
  data() {
    return {
      src: [],
      recommend: [], //type是7的数据
      banner: banners
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() {
    this.getInfo()
  },
  methods: {
    // 获取banner
    async getInfo() {
      const bannerINfo = {
        channel_id: this.channelId,
        psize: 20,
        pn: 1
      }
      const data = await lnicioBanner(bannerINfo)
      for (let item of data.result) {
        if (item.type == 1) {
          this.src = item.block_list   // banner
        } else if (item.type == 7) {   // 模块列表
          this.recommend.push(item)
          localStorage.setItem("recommend", JSON.stringify(this.recommend))
        }
      }
    },
    // 去播放页面
    toPlays(vod_id) {
      const query = {
        vod_id
      }
      this.$router.push({
        path: '/play',
        query
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
}

.banner img {
  width: 100%;
}

::v-deep .van-swipe__track {
  height: 208.33px;
}
::v-deep .van-image__error, .van-image__img, .van-image__loading{
  height: auto;
}
</style>
