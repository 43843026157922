<template>
    <div class="register">
      <div style="height:1rem;"></div>
      <div class="label_text">
        SIGN UP
      </div>

        <van-form ref="form" @submit="onSubmit" :model="form">
            <van-cell>Email</van-cell>
            <van-field v-model="form.account" name="username" clearable />

            <div style="height:0.4rem;"></div>
            <van-cell>Password</van-cell>
            <van-field v-model="form.password" name="password"  :type="passwordInputType"
                :right-icon="passwordInputIcon" clearable @click-right-icon="togglePasswordInputType" />

            <div style="height:0.4rem;"></div>
            <van-cell>Confirm Password</van-cell>
            <van-field v-model="form.confirmPassword" name="confirmPassword"
                :type="confirmPasswordInputType"
                :right-icon="confirmPasswordInputIcon" clearable @click-right-icon="toggleconfirmPasswordInputType" />

            <div style="margin: 16px;">
                <van-button type="danger" round block :disabled="submitButtonDisabled" native-type="submit">
                    Sign Up
                </van-button>
            </div>
        </van-form>
        <div class='toLogin' @click="toLogin()">
          <div style="opacity: 0.7; var(--title-color); ">Already have an account?&nbsp;</div>
          <div @click="toLogin()" style="opacity: 1; var(--title-color);">Sign In</div>
        </div>
    </div>
</template>
<script>
import { publicreg } from '@/api/loginRegister/login'
export default {
    name: '',
    components: {
    },
    data() {
        return {
            form: {
                account: '',
                password: '',
                confirmPassword: ''
            },
            showPassword: false,
            showconfirmPassword: false,
        }
    },
    // 计算属性
    computed: {
        passwordInputType() {
            return this.showPassword ? 'text' : 'password';
        },
        confirmPasswordInputType() {
            return this.showconfirmPassword ? 'text' : 'password';
        },
        passwordInputIcon() {
            return this.showPassword ? 'eye-o' : 'eye';
        },
        confirmPasswordInputIcon() {
            return this.showconfirmPassword ? 'eye-o' : 'eye';
        },
        validateForm() {
            return this.form.account.length >= 6 &&
                this.form.password.length >= 6 &&
                this.form.confirmPassword.length >= 6 &&
                this.form.password === this.form.confirmPassword
        },
        submitButtonDisabled() {
            return !this.form.account || !this.form.password || !this.form.confirmPassword
        }
    },
    watch: {},
    mounted() { },
    methods: {
        onSubmit(values) {
            if (!this.validateForm) {
                if (this.form.account.length < 6 || this.form.password.length < 6 || this.form.confirmPassword.length < 6) {
                    this.$toast('Alfabeto ou número, pelo menos 6 caracteres')
                } else {
                    this.$toast('Senha e confirmar senha diferente, tente novamente')
                }
                return false
            }
            const input = {
                account:values.username,
                password:values.password
            }
            publicreg(input)
        },
        //顶部导航返回
        onClickLeft() {
            this.$router.go(-1)
        },
        //跳转去登录页
        toLogin() {
            this.$router.push('/login')
        },
        togglePasswordInputType() {
            this.showPassword = !this.showPassword;
        },
        toggleconfirmPasswordInputType() {
            this.showconfirmPassword = !this.showconfirmPassword;
        },
    }
}
</script>
<style lang="scss" scoped>

.label_text{
  font-size: 24px;
  font-weight: bold;
  color: var(--title-color);
  opacity: 1;
  padding-left: 15px;
  margin-bottom: 10px;
}

/deep/.van-button {
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
}

/deep/.van-nav-bar {
    background: none;
}

/deep/.van-icon-arrow-left {
    font-size: 20px;
    color: var(--minor-text-color) !important;
}

/deep/.van-nav-bar__title {
    color: var(--minor-text-color);
}

.topNav {
    margin-bottom: 30px;
    border-bottom: 1px solid var(--minor-text-color);
    opacity: 0.6;
}

.icon {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    justify-items: center;
}

/deep/.van-grid-item__content {
    background-color: var(--main-color) !important;
}

/deep/.van-field__control {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: 0px;
    color: var(--minor-text-color);
}

.van-cell {
    font-size: 16px;
    background-color: var(--main-color);
    border: none !important;
  padding: 0.05rem 0.42667rem;
}

/deep/.van-field__body {
    background-color: var(--minor-color) !important;
    padding: 10px !important;
    border-radius: 2px !important;
}

.van-cell__value--alone {
  color: var(--title-color);
  opacity: 0.7;
  text-align: left;
}

.van-cell::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: none;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}

.dica {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: var(--title-color);
    opacity: 0.6;
    padding: 10px 26px;
}

.toLogin {
    list-style: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    display: flex;
    justify-content: center;
}

/deep/.van-button--round {
    background: #ee0a24;
    border-color: #ee0a24;
}
</style>
