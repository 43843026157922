<template>
    <div class="login">
        <div style="height:1rem;"></div>
        <div class="label_text">
            SIGN IN
        </div>
        <div class="loginForm">
            <van-form @submit="onSubmit">
                <van-cell>Email</van-cell>
                <van-field v-model="username" name="account"
                    :rules="[{ required: true, message: 'Input your email address' }]" />

                <div style="height:0.4rem;"></div>

                <van-cell>Password</van-cell>
                <van-field v-model="password" :type="passwordInputType" name="password"
                    :rules="[{ required: true, message: 'Input password' }]"
                    :right-icon="passwordInputIcon" @click-right-icon="showPassword = !showPassword" />

                <div style="opacity: 1; color: #3198bd; font-size: 12px; text-align: right; padding-right: 15px;">Forgot Password?</div>

                <div style="margin: 16px;">
                    <van-button round block type="danger" native-type="submit">Sign In</van-button>
                </div>
            </van-form>

            <div class="loginFoot">
                <div style="opacity: 0.7; color: var(--title-color); ">Don't have an account yet?&nbsp;</div>
                <div @click="toRegister()" style="opacity: 1;var(--title-color);">Sign Up</div>

            </div>
        </div>
    </div>
</template>
<script>
import { publicLogin } from '@/api/loginRegister/login.js'
export default {
    name: '',
    components: {},
    data() {
        return {
            username: '', //账号
            password: '', //密码
            showPassword: false, //密码框的类型
        }
    },
    // 计算属性
    computed: {
        passwordInputType() {
            return this.showPassword ? 'text' : 'password';
        },
        passwordInputIcon() {
            return this.showPassword ? 'eye-o' : 'eye';
        }
    },
    watch: {},
    mounted() { },
    methods: {
        onSubmit(values) {
            // 登录
            publicLogin(values).then(res => {
                localStorage.setItem('token', res.result.token)
                localStorage.setItem('ueserInfo', JSON.stringify(res.result))
                this.$router.go(-1)
            })

        },
        //跳转去注册
        toRegister() {
            this.$router.push('/register');
        },
    }
}
</script>
<style lang="scss" scoped>
.icon {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    justify-items: center;
}

.label_text{
    font-size: 24px;
    font-weight: bold;
    color: var(--title-color);
    opacity: 1;
    padding-left: 15px;
    margin-bottom: 10px;
}

/deep/.van-button {
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
}

/deep/.van-grid-item__content {
    background-color: var(--main-color) !important;
}

/deep/.van-field__control {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: 0px;
    color: var(--minor-text-color);
}



/deep/.van-field__body {
    background-color: var(--minor-color) !important;
    padding: 10px !important;
    border-radius: 2px !important;
}

.van-cell {
  background-color: var(--main-color);
  font-weight: normal;
  border: none !important;
  font-size: 16px;
  padding: 0.05rem 0.42667rem;
}

.van-cell__value--alone {
  color: var(--title-color);
  opacity: 0.7;
  text-align: left;
}

.van-cell::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: none;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}

.loginFoot {
    list-style: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    display: flex;
    justify-content: center;
}
</style>
