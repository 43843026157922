import axios from 'axios'
import CryptoJS from 'crypto-js'
import {Toast} from 'vant';
import {getAppLanguage, getAppId } from "@/utils/init";

// 配置请求的基准URL地址
const requests = axios.create(
    {
        baseURL: 'https://albd.h4c5.com/api',
        // headers: getHeaders(),
        timeout: 5000,
        responseType: 'text'
    }
)

// 重试次数
const MAX_RETRY = 3;

requests.interceptors.request.use((config) => {
    const headers = getHeaders()
    const token = localStorage.getItem('token')
    if (token) {
        headers['token'] = token
    }
    config.headers = headers
    const headerstr = JSON.stringify(config.headers)
    localStorage.setItem('headers', headerstr)
    config.retryCount = config.retryCount || 1;
    return config
})// 数据拦截

requests.interceptors.response.use((res) => {
    const data = aes_128_decrypt(res.data);
    // console.log(data)
    return JSON.parse(data);
}, error => {
    console.log(error)

    const config = error.config;
    if (!config || config.retryCount >= MAX_RETRY) {
        Toast({
            message: error.message,
            position: 'bottom',
        });
        return {}
    } else {
        // 设置重试次数
        config.retryCount += 1;

        // 创建一个新的 Promise 处理重试
        let backoff = new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });

        // 返回 Promise 调用 axios 重试请求
        return backoff.then(() => {
            return requests(config);
        });
    }
})

const key = '0123456789123456';
const iv = '2015030120123456'

function getHeaders() {
    let hostname = window.location.hostname

    // 不展示18+的域名
    let forbid_al_dist = [
        'www.freecine.vip',
        'www.freecine.cn',
        'enbbe7xs2dt5hz.8vy4.com',
        'www.8vy4.com',
        'enbbuc76b5mqpj.k5ca.com',
        'enb.8vy4.com',
        'www.k5ca.com',
        'localhost'
    ]

    let app_id = getAppId()
    let time = Date.now()
    const device_id = CryptoJS.MD5(navigator.userAgent).toString()
    const sign = 'ppcineweb123' + device_id + time;
    let en_al = 1
    if (forbid_al_dist.includes(hostname)) {
        en_al = 0
    }

    return {
        'app_id': app_id,
        'channel_code': 'ppcinewebb_1000',
        'device_id': device_id,
        'cur_time': time,
        'sign': CryptoJS.MD5(sign).toString().toUpperCase(),
        'token': '',
        'version': 30007,
        'sys_platform': 3,
        'mobmodel': '',
        'sysrelease': '',
        'mob_mfr': '',
        'app_language': getAppLanguage(),
        'domain': hostname,
        'en_al': en_al
    }
}


function aes_128_decrypt(text) {
    const decryptedData = CryptoJS.AES.decrypt(
        text,
        CryptoJS.enc.Utf8.parse(key),
        {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );
    return decryptedData.toString(CryptoJS.enc.Utf8)
}

export default requests  // 暴露
