import request from '@/utils/requests'
import qs from 'qs'

// 登录
export const publicLogin = data => {
    return request({
        url: '/public/login',
        method: 'post',
        data: qs.stringify(data)
    })
}
export const publicreg = data => {
    return request({
        url: '/public/register',
        method: 'post',
        data: qs.stringify(data)
    })
}