import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchInfo: null,//搜索建议的数据
    searchValue: null,//在历史搜索页面 当点机热门搜索和搜索建议得value值
  },
  mutations: {
    INCREMENT(state, searchInfo) {
      state.searchInfo = searchInfo
    },
    GETVALUE(state, searchValue) {
      state.searchValue = searchValue
    },
  },
  actions: {
    getSearchInfo({ commit }, amount) {
      commit('INCREMENT', amount)
    },
    getSearchValue({ commit }, amount) {
      commit('GETVALUE', amount)
    }
  },
  getters: {
    getInfo: state => state.searchInfo,
    getvalue: state => state.searchValue,
  },
  
  modules: {
  }
})
