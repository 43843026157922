<template>
  <div id="app">
    <keep-alive :include="include" :exclude="exclude">
      <router-view/>
    </keep-alive>
  </div>
</template>

<style lang="scss">
html {
  background-color: var(--main-color);

  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border: none;
  }
  -webkit-text-size-adjust: 100%;
}
</style>
<script>
export default {
  data() {
    return {
      include: ['home', 'class', 'stream'],
      exclude: []
    };
  },
  methods: {
    excludeKeepAlive(exclude_item) {
      this.exclude = exclude_item; // 修改 key 的值，强制重新加载组件
    }
  }
}
</script>
