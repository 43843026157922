import request from '@/utils/requests'
import qs from 'qs'


// 登录
export const lnicioBanner = data => {
  return request({
    url: '/channel/get_info',
    method: 'post',
    data:qs.stringify(data)
  })
}